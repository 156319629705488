import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/useAuth";
import LoadingScreen from "./LoadingScreen";
import PostSuccessScreen from "./PostSuccessScreen";

export default function BookingCard({ bookings, timeSlot,setShowModal,  setBookingId }) {
  const [options, setOptions] = useState(0);
  const { authToken } = useContext(AuthContext);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleBookingClick = (booking) => {
    console.log(booking);
    if (booking && booking.booking_status === "accepted") {
      setShowModal(true);
      setBookingId(booking);
    }
  };

  const handleCancel = (cancel, booking) => {
    axios
      .post(
        `${API_ENDPOINT}/booking/updateBooking`,
        {
          status: cancel ? "cancel" : "done",
          booking_id: booking.booking_id,
        },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setUpdated(true);
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        setUpdated(true);
      });
  };

  return (
    <td
      className={` border text-[10px] font-medium text-center py-4 px-10 cursor-pointer ${
        bookings.find((booking) => booking.slot_time === timeSlot).booking_status === "cancel" ?'bg-[#FFA6A6]' : 'bg-[#CBFBCA]'
      } relative`}
      onClick={() =>
        handleBookingClick(
          bookings.find((booking) => booking.slot_time === timeSlot)
        )
      }
    >
      <p className="">
      <span className="pr-1 font-bold">Name : 
        </span>  
       {bookings.find((booking) => booking.slot_time === timeSlot)
        ?.customer_name || "-"}
        </p>
        <p className="py-1">
        <span className="font-bold pr-1">Phn.No:
          </span> 
        {bookings.find((booking) => booking.slot_time === timeSlot)
        ?.customer_phone || "-"}
        </p>
        <p className="">
          <span className="font-bold pr-1">
            Price : 
          </span>
        ₹ {bookings.find((booking) => booking.slot_time === timeSlot)
        ?.cost || "-"}

        </p>
        
        
      {/* {loading ? (
        "Updating Booking..."
      ) : updated ? (
        <PostSuccessScreen
          successMessages={["Updated Booking Successfully"]}
          errorMessages={["Couldn't update booking", "Try again!"]}
          success={success}
          height={"100px"}
        />
      ) : (
        
      )} */}
    </td>
  );
}
